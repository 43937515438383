<template>
  <div id="tiktok-logs-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-tools"></i>
          <span style="margin-left: 5px"> Fixing </span>
        </b-card-title>
        <!-- filter component -->
        <div class="d-flex flex-wrap">
          <b-button
            variant="outline-info"
            class="ml-auto mr-1"
            v-b-modal.modal-add-fixing
          >
            <feather-icon icon="PlusIcon" />
            <span>Tambah Data Fixing</span>
          </b-button>
          <!-- date filter -->
          <date-picker-component class="mr-1" />
          <!-- scraper filter -->
          <b-input-group
            class="input-group-merge mr-1"
            style="width: max-content;height: 3rem !important;"
          >
            <b-input-group-prepend is-text style="margin-right: -5px;">
              <feather-icon icon="GlobeIcon" />
            </b-input-group-prepend>
            <div
              class="scraper-dropdown"
              style="z-index: 3 !important;border-left: none !important;"
            >
              <Dropdown
                :options="[
                  { id: 'all', name: 'Semua Scraper' },
                  ...scraper_options,
                ]"
                v-on:selected="scraperSelection"
                :disabled="false"
                :maxItem="100"
                placeholder="Semua Scraper"
              />
            </div>
          </b-input-group>
          <!-- scraper filter -->
          <b-input-group
            class="input-group-merge"
            style="width: max-content;height: 3rem !important;"
          >
            <b-input-group-prepend is-text style="margin-right: -5px;">
              <feather-icon icon="UserIcon" />
            </b-input-group-prepend>
            <div
              class="scraper-dropdown"
              style="z-index: 3 !important;border-left: none !important;"
            >
              <Dropdown
                :options="[{ id: 'all', name: 'Semua User' }, ...user_options]"
                v-on:selected="userSelection"
                :disabled="false"
                :maxItem="1000"
                placeholder="Semua User"
              />
            </div>
          </b-input-group>
        </div>
      </b-card-header>
      <b-card-body class="pb-0">
        <div class="d-flex justify-content-between" style="gap: 15px;">
          <!-- error status -->
          <div
            class=" border rounded-lg"
            style="border-style: dashed !important;padding:10px 30px 10px 30px;border-width: 2px !important; width: 25%;"
          >
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-danger rounded-circle"
                  style="width: 48px; height: 48px"
                >
                  <span class="b-avatar-custom">
                    <i class="bi bi-x-circle fs-18"></i>
                  </span>
                </span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">{{ stats.error }}</h4>
                <p class="card-text font-small-3 mb-0">Error</p>
              </div>
            </div>
          </div>
          <!-- missing class status -->
          <div
            class=" border rounded-lg"
            style="border-style: dashed !important;padding:10px 30px 10px 30px;border-width: 2px !important; width: 25%;"
          >
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-warning rounded-circle"
                  style="width: 48px; height: 48px"
                >
                  <span class="b-avatar-custom">
                    <i class="bi bi-exclamation-circle fs-18"></i>
                  </span>
                </span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.missing_class }}
                </h4>
                <p class="card-text font-small-3 mb-0">Missing Class</p>
              </div>
            </div>
          </div>
          <!-- fixing status -->
          <div
            class=" border rounded-lg"
            style="border-style: dashed !important;padding:10px 30px 10px 30px;border-width: 2px !important; width: 25%;"
          >
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-primary rounded-circle"
                  style="width: 48px; height: 48px"
                >
                  <span class="b-avatar-custom">
                    <i class="bi bi-tools fs-18"></i>
                  </span>
                </span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.fixing }}
                </h4>
                <p class="card-text font-small-3 mb-0">Fixing</p>
              </div>
            </div>
          </div>
          <!-- redirected status -->
          <div
            class=" border rounded-lg"
            style="border-style: dashed !important;padding:10px 30px 10px 30px;border-width: 2px !important; width: 25%;"
          >
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-info rounded-circle"
                  style="width: 48px; height: 48px"
                >
                  <span class="b-avatar-custom">
                    <i class="bi bi-repeat fs-18"></i>
                  </span>
                </span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">{{ stats.redirected }}</h4>
                <p class="card-text font-small-3 mb-0">Redirected</p>
              </div>
            </div>
          </div>
          <!-- unavailable status -->
          <div
            class=" border rounded-lg"
            style="border-style: dashed !important;padding:10px 30px 10px 30px;border-width: 2px !important; width: 25%;"
          >
            <div class="media">
              <div class="media-aside mr-2 align-self-start">
                <span
                  class="b-avatar badge-light-secondary rounded-circle"
                  style="width: 48px; height: 48px"
                >
                  <span class="b-avatar-custom">
                    <i class="bi bi-slash-circle fs-18"></i>
                  </span>
                </span>
              </div>
              <div class="media-body my-auto">
                <h4 class="font-weight-bolder mb-0">{{ stats.unavailable }}</h4>
                <p class="card-text font-small-3 mb-0">Unavailable</p>
              </div>
            </div>
          </div>
        </div>
      </b-card-body>
      <!-- loader component -->
      <loader-component v-if="is_loading" />
      <!-- table-component -->
      <table-component
        v-else
        :data="table_data"
        class="mt-1"
        :editItems="editItems"
        :deleteItems="deleteItems"
      />
    </b-card>
    <!-- add fixing  modal component -->
    <add-fixing-component
      :scraper_options="scraper_options"
      :problem_classification_options="problem_classification_options"
    />
    <!-- edit fixing modal component -->
    <edit-fixing-component
      :data="fixing_data"
      :scraper_options="scraper_options"
    />
  </div>
</template>
<script>
import axios from "axios";
import $ from "jquery";
import useJwt from "@/auth/jwt/useJwt";
import dataTableClientPagination from "@/views/components/dataTableClientPagination.vue";
import datePicker from "@/views/components/datePicker.vue";
import loader from "@/views/components/loader.vue";
import addFixingData from "../components/fixing/addFixingData.vue";
import editFixingData from "../components/fixing/editFixingData.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Dropdown from "vue-simple-search-dropdown";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";

export default {
  name: "Fixing",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    Dropdown,
    "table-component": dataTableClientPagination,
    "loader-component": loader,
    "add-fixing-component": addFixingData,
    "edit-fixing-component": editFixingData,
    "date-picker-component": datePicker,
  },
  data() {
    return {
      fixing_data: null,
      is_loading: true,
      from_date: this.$route.query.from_date,
      to_date: this.$route.query.to_date,
      selected_scraper: "all",
      selected_user: "all",
      page: this.$route.query.page ? this.$route.query.page : 1,
      items: this.$route.query.items ? this.$route.query.items : 10,
      scraper_options: [],
      problem_classification_options: [],
      user_options: [],
      stats: {
        error: 0,
        redirected: 0,
        missing_class: 0,
        unavailable: 0,
        fixing: 0,
      },
      table_data: {
        page_info: {
          limit: 2,
          page: 1,
        },
        filter: "",
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "status_awal",
            label: "Status",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "username",
            label: "pic",
            sortable: true,
            thStyle: { width: "12rem" },
          },
          {
            key: "scraper_name",
            label: "Nama Scraper",
            sortable: true,
            thStyle: { width: "15rem" },
          },
          {
            key: "problem_classification",
            label: "klasifikasi masalah",
          },
          {
            key: "error_message",
            label: "Pesan Error",
          },
          {
            key: "solution",
            label: "Solusi",
            sortable: true,
          },
          {
            key: "updated_at",
            label: "Tanggal Fixing",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "5rem" },
          },
          {
            key: "actions",
            label: "Aksi",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "5rem" },
          },
        ],
        items: null,
      },
      cancelToken: null,
    };
  },
  created() {
    this.getProblemClassification();
    this.getUserData();
    this.getScraperData();
    this.getData();
  },
  watch: {
    "$route.query"() {
      this.from_date = this.$route.query.from_date;
      this.to_date = this.$route.query.to_date;
      this.getData();
    },
  },
  methods: {
    userSelection(data) {
      this.selected_user = data.id;
      this.getData();
    },
    scraperSelection(data) {
      this.selected_scraper = data.name;
      this.getData();
    },
    deleteItems(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: `Data Fixing Akan dihapus dari sistem`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let api = process.env.VUE_APP_API_URL + "fixing-scraper/delete/" + id;
          useJwt.axiosIns
            .delete(api)
            .then(() => {
              this.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Data fixing berhasil dihapus`,
                },
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal !",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: `Data fixing gagal dihapus`,
                },
              });
            });
        }
      });
    },
    editItems(id) {
      let find_data = this.table_data.items.find((el) => el._id == id);
      if (find_data) {
        this.fixing_data = {
          _id: find_data._id,
          scraper_name: find_data.scraper_name,
          problem_classification: find_data.problem_classification || null,
          solution: find_data.solution,
          message: find_data.error_message,
        };
        this.$bvModal.show("modal-edit-fixing");
      }
    },
    getUserData() {
      let params = {
        page: 1,
        limit: 1000,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "users/?" + query;
      useJwt.axiosIns.get(api).then((res) => {
        this.user_options = res.data.users || [];
        this.user_options = this.user_options.map((el) => ({
          id: el.username,
          name: el.nama,
        }));
      });
    },
    getProblemClassification() {
      let params = {
        page: 1,
        item: 1000,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "problem-classification?" + query;
      useJwt.axiosIns.get(api).then((res) => {
        this.problem_classification_options =
          res.data.problem_classification_data || [];
        this.problem_classification_options = this.problem_classification_options.map(
          (el) => ({
            id: el._id,
            name: el.name,
          })
        );
      });
    },
    getScraperData() {
      let api = process.env.VUE_APP_API_URL + "scraper/options";
      useJwt.axiosIns.get(api).then((res) => {
        this.scraper_options = res.data.scraper_data;
        this.scraper_options = this.scraper_options.map((el) => ({
          id: el._id,
          name: el._id,
        }));
      });
    },
    getData() {
      this.is_loading = true;
      if (this.cancelToken) {
        this.cancelToken.cancel();
        this.is_loading = true;
      }
      this.cancelToken = axios.CancelToken.source();
      let params = {
        from_date: this.from_date,
        to_date: this.to_date,
        ...(this.selected_user && this.selected_user !== "all"
          ? { username: this.selected_user }
          : {}),
        ...(this.selected_scraper && this.selected_scraper !== "Semua Scraper"
          ? { scraper_name: this.selected_scraper }
          : {}),
        page: this.page,
        items: this.items,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "fixing-scraper?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelToken.token })
        .then((res) => {
          this.table_data.items = res.data.fixing_data || [];
          if (this.table_data.items.length > 0) {
            this.stats.error = this.table_data.items.filter(
              (el) => el.status_awal == "Error"
            ).length;
            this.stats.missing_class = this.table_data.items.filter(
              (el) => el.status_awal == "MissingClass"
            ).length;
            this.stats.unavailable = this.table_data.items.filter(
              (el) => el.status_awal == "Unavailable"
            ).length;
            this.stats.redirected = this.table_data.items.filter(
              (el) => el.status_awal == "Redirected"
            ).length;
            this.stats.fixing = this.table_data.items.filter(
              (el) => !el.status_awal
            ).length;
            this.table_data.items.sort((a, b) => {
              const date_a = new Date(a.updated_at);
              const date_b = new Date(b.updated_at);
              return date_b - date_a;
            });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
        });
    },
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
  },
};
</script>
<style>
.modal-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 20px !important;
  font-weight: bold;
}
.scraper-dropdown .dropdown-input {
  border-left: none !important;
  border: 1px #d8d6de solid;
  border-radius: 0 5px 5px 0 !important;
  height: 3rem !important;
  min-width: 100% !important;
  font-size: 14px !important;
  background-color: transparent !important;
}
.scraper-dropdown .dropdown-input:focus {
  outline: none !important;
}
.scraper-dropdown .dropdown-content {
  min-width: 100% !important;
  border-radius: 5px !important;
}
.scraper-dropdown .dropdown-content .dropdown-item {
  font-size: 14px !important;
}
.dark-layout * .scraper-dropdown .dropdown-input {
  border: 1px #484849 solid;
}
.dark-layout * .scraper-dropdown .dropdown-input {
  color: #bfbec3 !important;
}
.dark-layout * .dropdown-content .dropdown-item {
  background-color: rgba(22, 29, 49, 0.9) !important;
  color: rgb(230, 225, 225) !important;
}
.dark-layout * .dropdown-content .dropdown-item:hover {
  color: white !important;
}
</style>
